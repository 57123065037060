<template>
  <section class="h-100 w-100 d-flex align-center justify-center">
    <v-sheet
      v-if="!isEmailSent"
      color="white"
      elevation="4"
      outlined
      rounded
      width="500"
      height="250"
      class="pa-5 mb-12 rounded-lg"
    >
      <div class="text-center title mb-8 mt-4">
        <p>Lupa Kata Sandi?</p>
      </div>
      <v-form
        id="forgotPassword"
        ref="forgotPassword"
        :rules="rules"
        @submit.native.prevent="submit">
        <v-text-field
          v-model="email"
          ref="email"
          placeholder="Masukkan email"
          :label="$_strings.welcomePage.EMAIL"
          :rules="rules.email"
          clearable
          dense
          class="mb-5"
          persistent-hint
        ></v-text-field>
        <div class="d-flex justify-center">
          <v-btn
            rounded
            large
            class="background-denim white--text text-center font-weight-bold body-2 w-50"
            type="submit">
            Kirim
          </v-btn>
        </div>
      </v-form>
    </v-sheet>
    <v-sheet
      v-else
      color="white"
      elevation="4"
      outlined
      rounded
      width="500"
      height="250"
      class="pa-5 mb-12 rounded-lg d-flex justify-center align-center"
    >
      <p class="body-1 font-weight-bold">Silahkan cek email anda untuk melanjutkan proses</p>
    </v-sheet>
    <welcome-footer/>
  </section>
</template>

<script>
import WelcomeFooter from './WelcomeFooter.vue';

export default {
  name: 'forgot-password',
  components: {
    WelcomeFooter,
  },
  data() {
    return {
      isEmailSent: false,
      email: '',
      rules: {
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
      },
    };
  },
  methods: {
    async submit() {
      const valid = this.$refs.forgotPassword.validate();
      if (!valid) return;
      try {
        this.$root.$loading.show();
        await this.$_services.account.emailResetPassword(this.email);
        this.$dialog.notify.success('Permintaan Berhasil Dikirim');
        this.isEmailSent = true;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
